<template>
  <div>
    <el-header>
      <el-select
        clearable
        filterable
        v-model="consumerId"
        @change="handleUserSelectChange"
        placeholder="选择会员"
      >
        <el-option
          v-for="item in userOptions"
          :key="item.id"
          :value="item.id"
          :label="item.userName"
        />
      </el-select>

      <el-date-picker
        v-model="dateRange"
        type="datetimerange"
        align="right"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        :picker-options="pickerOptions"
        :default-time="['00:00:00', '23:59:59']"
        @change="handleDateRangeChange"
      >
      </el-date-picker>
    </el-header>

    <el-table
      stripe
      :data="dataList"
      height="44rem"
      style="width: 100%"
    >
      <template v-for="column of columns">
        <el-table-column
          :key="column.prop"
          v-bind="column"
        ></el-table-column>
      </template>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="sizeList"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    />
  </div>
</template>

<script>
import apis from '@/apis/apis'

export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      consumerId: '',
      dataList: [],
      columns: [
        { label: '会员名称', prop: 'userName', width: 'auto' },
        { label: '消费项目', prop: 'consumePoj', width: 'auto' },
        { label: '消费金额', prop: 'consumeMoney', width: 'auto' },
        { label: '消费备注', prop: 'consumeNotes', width: 'auto' },
        { label: '消费日期', prop: 'consumeDate', width: 'auto' },
      ],
      page: 1,
      pageSize: window.localStorage.getItem('recordListPageSize') - 0 || 10,
      total: 0,
      sizeList: [5, 10, 20, 30, 50, 100, 200],

      dateRange: ['', ''],
      userOptions: [],
    }
  },
  created() {
    this.getUserOptions()
    this.getRecordList()
  },
  methods: {
    async getUserOptions() {
      try {
        const { data: res } = await this.$GET(apis.member.userOptions)
        if (res.code !== 200) return
        this.userOptions = res.data
      } catch (e) {
        console.log('e', e)
      }
    },
    async getRecordList() {
      try {
        const [startTime, endTime] = this.dateRange || ['', '']

        const params = {
          page: this.page,
          pageSize: this.pageSize,
          // queryStr: '',
          consumerId: this.consumerId,
          startTime,
          endTime,
        }
        const { data: res } = await this.$GET(
          apis.member.consumerRecord,
          params
        )
        const { code, data, total } = res
        if (code !== 200) return

        this.dataList = data
        this.total = total
      } catch (e) {
        console.log('e', e)
      }
    },
    handleSizeChange(val) {
      this.page = 1
      this.pageSize = val
      window.localStorage.setItem('recordListPageSize', val)
      this.getRecordList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getRecordList()
    },
    handleDateRangeChange(val) {
      this.page = 1
      if (val === null) this.dateRange = ['', '']
      this.getRecordList()
    },
    handleUserSelectChange() {
      this.page = 1
      if (!this.consumerId) this.consumerId = ''
      this.getRecordList()
    },
  },
}
</script>

<style lang='scss' scoped>
.el-header {
  display: flex;
  justify-content: space-between;
  padding: 0;
  // align-items: center;
}
</style>
